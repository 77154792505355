<template>
  <chat-window
    :current-user-id="currentUserId"
    :rooms="rooms"
    class="chat-window"
    :message-actions="[]"
    :messages-loaded="messagesLoaded"
    :messages="messages"
    :roomsLoaded="roomsLoaded"
    :show-audio="false"
    :show-files="false"
    style="height: 900px"
    :show-reaction-emojis="false"
    :single-room="singleRoom"
    @send-message="sendMessage"
    @fetch-messages="fetchMessages"
    :show-footer="rooms[0].users.map(u => u._id).includes(user.id)"
  />
</template>

<script>
import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import { mapGetters } from 'vuex'

export default {
  name: 'ChatComponent',
  components: {
    ChatWindow
  },
  props: {
    rooms: {
      type: Array,
      required: true
    },
    roomsLoaded: {
      type: Boolean
    },
    currentUserId: {
      type: Number
    },
    singleRoom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      messagesLoaded: false,
      messages: []
    }
  },
  methods: {
    sendMessage ({
      content,
      roomId,
      files,
      replyMessage
    }) {
      this.$http.post(`chats/${roomId}/messages`, { message: content })
        .then(res => {
          this.messages.push({
            _id: res.data.id,
            content: res.data.message,
            senderId: res.data.fromUserId
          })
        })
    },
    fetchMessages ({ room }) {
      this.messagesLoaded = false
      this.$http.get(`chats/${room.roomId}`)
        .then(res => {
          this.messages = res.data.chatMessages.map(message => {
            message._id = message.id
            message.content = message.message
            message.senderId = message.fromUserId
            return message
          })
          this.messagesLoaded = true
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user'
    })
  },
  updated () {
    // console.log(this.rooms)
  }
}
</script>

<style scoped>

</style>
