<template>
  <div>
    <!-- Start confirm dialog -->
    <v-dialog
      persistent
      v-model="confirmDemandDialog">
      <v-card>
        <v-card-title class="text-h5">
          Potvrdit poptávku?
        </v-card-title>

        <v-card-text>
          Tato akce je nevratná
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            :disabled="confirmingDemand"
            @click="confirmDemandDialog = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="success"
            text
            :loading="confirmingDemand"
            @click="confirmDemand"
          >
            Potvrdit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End confirm dialog -->

    <v-alert type="error" v-if="demand.canceled">Poptávka byla zrušena</v-alert>
    <v-tabs v-model="tab">
      <v-tab>
        Rychlý přehled
      </v-tab>
      <v-tab>
        Chat
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <template
            v-if="!loading">
            <v-btn
              color="success"
              @click="confirmDemandDialog = true"
              v-if="!isAdmin"
            >Potvrdit poptávku</v-btn>
            <CardMiniatureHorizontalDemand :demand="demand" :property-id="demand.property.id" :room-id="demand.room? demand.room.id : null" />
            <DemandForm
              :demand="demand"
              :inputs-disabled="demand.canceled || demand.deletedAt"
              @edit-success="reload"
            ></DemandForm>
          </template>
          <v-skeleton-loader
            v-else
            type="article"
          ></v-skeleton-loader>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <ChatComponent
          :current-user-id="user.id"
          :rooms="[chatRoom]"
          :single-room="true"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DemandForm from '../../components/demands/DemandForm'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CardMiniatureHorizontalDemand from '../../components/property/DemadPropertyCardMiniature'
import ChatComponent from '@/components/chat/ChatComponent'
export default {
  name: 'DemandDetail',
  components: {
    ChatComponent,
    CardMiniatureHorizontalDemand,
    DemandForm
  },
  data () {
    return {
      tab: 0,
      confirmingDemand: false,
      confirmDemandDialog: false,
      chatRoom: {}
    }
  },
  created () {
    const url = this.isAdmin ? `/admin/demands/${this.$route.params.id}` : `/demands/${this.$route.params.id}`
    this.$http.get(url)
      .then(({ data }) => {
        // redirect pro rezervace
        if (data.reservation) {
          let redirectName
          if (this.isAdmin) {
            redirectName = 'admin.reservations.reservation'
          } else {
            redirectName = 'host.reservations.reservation'
          }

          this.$router.replace({
            name: redirectName,
            params: {
              id: data.id
            }
          })
        }
        this.setDemand(data)
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.setAlerts([{
            message: 'Poptávka nenalezena',
            type: 'error'
          }])
        }
      })

    this.$http.get(`/demands/${this.$route.params.id}/chat`)
      .then(res => {
        res.data.user._id = res.data.user.id
        res.data.user.username = res.data.user.email
        res.data.host._id = res.data.host.id
        res.data.host.username = `${res.data.host.firstName} ${res.data.host.lastName}`
        // console.log(res.data)
        this.chatRoom = {
          roomId: res.data.id,
          roomName: `Poptávka č. ${res.data.demandId}`,
          users: [res.data.user, res.data.host]
        }
      })
  },
  computed: {
    loading () {
      return !Object.keys(this.demand).length
    },
    ...mapGetters({
      demand: 'detailViews/demand',
      isAdmin: 'userAuth/isAdmin',
      user: 'userAuth/user'
    })
  },
  methods: {
    reload () {
      location.reload()
    },
    confirmDemand () {
      this.confirmingDemand = true
      this.$http.post(`/host/demands/${this.demand.id}/confirm`)
        .then(res => {
          this.$router.push({
            name: 'host.reservations.reservation',
            params: {
              id: this.demand.id
            }
          })
        })
        .catch(({ response }) => {
          if (response.data.message === 'TIMESLOT_NOT_AVAILABLE') {
            this.setAlerts([{
              message: 'Termín je již obsazený',
              type: 'error'
            }])
          }
        })
        .finally(() => {
          this.confirmDemandDialog = false
          this.confirmingDemand = false
        })
    },
    ...mapMutations({
      setDemand: 'detailViews/setDemand'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  }
}
</script>

<style scoped>

</style>
